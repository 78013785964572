import React, { useState, useRef } from 'react';
import { ArrowRight } from 'lucide-react';

interface GlowButtonProps {
  className?: string;
}

const GlowButton: React.FC<GlowButtonProps> = ({ className = '' }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isWithinBounds, setIsWithinBounds] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!buttonRef.current) return;

    const rect = buttonRef.current.getBoundingClientRect();
    const isInside =
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom;

    setIsWithinBounds(isInside);

    if (isInside) {
      const x = e.clientX - rect.left;
      setMousePosition({ x, y: 0 });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsWithinBounds(false);
  };

  const createGlowGradient = (position: number) => {
    const distanceFromEdge = Math.min(position / 160, (320 - position) / 160);

    if (distanceFromEdge > 0.3) {
      return `
        radial-gradient(
          circle at center,
          rgba(255, 255, 255, 1) 0%,
          rgba(219, 234, 254, 1) 10%,
          rgba(147, 197, 253, 0.95) 20%,
          rgba(59, 130, 246, 0.9) 30%,
          rgba(37, 99, 235, 0.7) 40%,
          rgba(29, 78, 216, 0.5) 50%,
          rgba(30, 64, 175, 0.3) 60%,
          rgba(30, 58, 138, 0.1) 70%,
          transparent 100%
        )
      `;
    }

    return staticGlowGradient;
  };

  const staticGlowGradient = `
    radial-gradient(
      circle at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(219, 234, 254, 1) 10%,
      rgba(147, 197, 253, 0.95) 20%,
      rgba(59, 130, 246, 0.9) 30%,
      rgba(37, 99, 235, 0.7) 40%,
      rgba(29, 78, 216, 0.5) 50%,
      rgba(30, 64, 175, 0.3) 60%,
      rgba(30, 58, 138, 0.1) 70%,
      transparent 100%
    )
  `;

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}
      className="relative inline-flex items-center"
    >
      {/* Base white button background */}
      <div className="absolute inset-0 rounded-full bg-white/80" />

      {/* Glow layers container */}
      <div className="absolute inset-0 overflow-visible">
        {/* Mouse-following glow */}
        <div
          className="absolute w-14 h-14"
          style={{
            background: createGlowGradient(mousePosition.x),
            filter: 'blur(10px)',
            left: `${mousePosition.x}px`,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: isHovered && isWithinBounds ? 1 : 0,
            transition: 'all 0.3s ease-out',
            pointerEvents: 'none',
            mixBlendMode: 'multiply',
          }}
        />

        {/* Static right glow */}
        <div
          className="absolute right-0 top-1/2 w-16 h-16"
          style={{
            background: staticGlowGradient,
            filter: 'blur(10px)',
            opacity: isHovered ? 0 : 0.9,
            transform: 'translate(25%, -50%)',
            transition: 'opacity 0.3s ease-out',
            mixBlendMode: 'multiply',
          }}
        />
      </div>

      {/* Semi-transparent white overlay */}
      <div className="absolute inset-0 rounded-full bg-white/60 backdrop-blur-sm" />

      {/* Button content */}
      <button
        ref={buttonRef}
        className={`relative flex items-center justify-center h-10 sm:pl-[59px] sm:pr-[52px] text-xs tracking-tight rounded-full border border-white/40 space-x-2 uppercase font-bold ${className}`}
      >
        <span className="text-blue-600">Try it Free</span>
        <ArrowRight className="w-4 h-4 text-blue-600" />
      </button>

      {/* Border glow */}
      <div
        className="absolute inset-0 rounded-full pointer-events-none"
        style={{
          border: '1px solid rgba(255, 255, 255, 0.6)',
          boxShadow: '0 0 8px rgba(59, 130, 246, 0.3)',
        }}
      />
    </div>
  );
};

export default GlowButton;
