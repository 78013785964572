import { Card } from '@/components/ui/card';
import Link from 'next/link';
import {
  Twitter,
  Facebook,
  Instagram,
  Youtube,
  Disc,
  Star,
} from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-slate-900 border-t border-slate-800">
      <div className="container mx-auto py-12 px-4">
        <div className="grid grid-cols-1 xl:grid-cols-4 gap-8">
          {/* Social Media Section */}
          <Card className="bg-slate-800/50 backdrop-blur-sm border-slate-700/50 shadow-xl rounded-xl p-6 transition-all duration-300 hover:shadow-2xl hover:shadow-blue-500/10">
            <h3 className="text-lg font-semibold text-slate-200 mb-6">
              Follow Us
            </h3>
            <div className="flex justify-between">
              <Link
                href="https://twitter.com/watchinspect"
                className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Twitter className="h-6 w-6" />
              </Link>
              <Link
                href="https://www.facebook.com/watchinspect"
                className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Facebook className="h-6 w-6" />
              </Link>
              <Link
                href="https://www.instagram.com/watchinspect"
                className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Instagram className="h-6 w-6" />
              </Link>
              <Link
                href="https://www.youtube.com/@WatchInspect"
                className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Youtube className="h-6 w-6" />
              </Link>
              <Link
                href="https://www.tiktok.com/@watchinspect"
                className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
              >
                <Disc className="h-6 w-6" />
              </Link>
            </div>
          </Card>

          {/* Navigation Links */}
          <div className="xl:col-span-3">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Support Section */}
              <Card className="bg-slate-800/50 backdrop-blur-sm border-slate-700/50 shadow-xl rounded-xl p-6 transition-all duration-300 hover:shadow-2xl hover:shadow-blue-500/10">
                <h3 className="text-lg font-semibold text-slate-200 mb-4">
                  SUPPORT
                </h3>
                <ul className="space-y-3">
                  <li>
                    <Link
                      href="#"
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      Help Center
                    </Link>
                  </li>
                </ul>
              </Card>

              {/* Corporate Section */}
              <Card className="bg-slate-800/50 backdrop-blur-sm border-slate-700/50 shadow-xl rounded-xl p-6 transition-all duration-300 hover:shadow-2xl hover:shadow-blue-500/10">
                <h3 className="text-lg font-semibold text-slate-200 mb-4">
                  CORPORATE
                </h3>
                <ul className="space-y-3">
                  <li>
                    <Link
                      href="#"
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </Card>

              {/* More Section */}
              <Card className="bg-slate-800/50 backdrop-blur-sm border-slate-700/50 shadow-xl rounded-xl p-6 transition-all duration-300 hover:shadow-2xl hover:shadow-blue-500/10">
                <h3 className="text-lg font-semibold text-slate-200 mb-4">
                  MORE...
                </h3>
                <ul className="space-y-3">
                  <li>
                    <Link
                      href="#"
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="#"
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="#"
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      Do Not Sell My Personal Information
                    </Link>
                  </li>
                </ul>
              </Card>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-12 pt-8 border-t border-slate-800">
          <p className="text-center text-slate-400">
            © 2021-2025 WatchInspect DBA - Quinones Digital Asset Management
            LLC. All rights reserved.
          </p>
          <p className="mt-4 text-center text-sm text-slate-500">
            All watch sales are derived in Miami, Florida solely by
            WatchInspect.
          </p>
        </div>

        {/* Trustpilot Section */}
        <div className="mt-8 flex justify-center">
          <Link
            href="https://www.trustpilot.com/review/watchinspect.com"
            target="_blank"
            rel="noopener"
            className="inline-flex items-center px-4 py-2 rounded-lg bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 text-slate-400 hover:text-blue-400 hover:border-blue-500/50 transition-all duration-300"
          >
            <span className="text-sm font-medium mr-2">Review us on</span>
            <Star className="h-4 w-4" />
            <span className="text-sm font-medium ml-2">Trustpilot</span>
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
