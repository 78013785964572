//hero-search.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { ArrowUpCircle, ArrowDownCircle } from 'lucide-react';
import debounce from 'lodash/debounce';
import { RolexImage } from '../../../../utils/RolexImage';
import {
  ISimpleDatafeed,
  SearchResult,
  SearchSymbolResultItem,
  ExtendedSearchSymbolResultItem,
} from '../dashboard/watchlist/watchlist.types';

const INITIAL_PLACEHOLDER = 'Search for a watch or model...';
const TYPING_SPEED = 100;
const DELAY_BEFORE_START = 4000;
const DISPLAY_DURATION = 4000;
const CLEAR_PAUSE = 200;
const EXACT_RESULTS = 4;

interface HeroSearchProps {
  onSymbolSelect: (symbol: SearchResult) => void;
  datafeed: ISimpleDatafeed;
}
interface TrendInfo {
  isPositive: boolean;
  changePercent: number;
  startPrice: number;
  endPrice: number;
}

interface ChartDataPoint {
  time: number;
  price: number;
}

const HeroSearch: React.FC<HeroSearchProps> = ({
  onSymbolSelect,
  datafeed,
}) => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [miniCharts, setMiniCharts] = useState<{
    [key: string]: ChartDataPoint[];
  }>({});
  const [trends, setTrends] = useState<{ [key: string]: TrendInfo }>({});
  const [previewIndex, setPreviewIndex] = useState(0);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [userInteracting, setUserInteracting] = useState(false);
  const [interactionTimer, setInteractionTimer] =
    useState<NodeJS.Timeout | null>(null);
  const [placeholder, setPlaceholder] = useState(INITIAL_PLACEHOLDER);
  const [isTyping, setIsTyping] = useState(false);

  const fetchWatchData = async () => {
    try {
      const response = await fetch('/api/trading-dashboard/watchData');
      const data: SearchResult[] = await response.json();

      // Deduplicate based on symbol before sorting
      const uniqueData = Array.from(
        new Map(data.map((item) => [item.symbol, item])).values()
      );

      const sortedModels = uniqueData
        .sort((a, b) => (b.dataCount || 0) - (a.dataCount || 0))
        .slice(0, 30);

      const previewWatches: SearchResult[] = [];
      const availableModels = [...sortedModels];

      // Select unique watches up to EXACT_RESULTS
      while (
        previewWatches.length < EXACT_RESULTS &&
        availableModels.length > 0
      ) {
        const randomIndex = Math.floor(Math.random() * availableModels.length);
        const selected = availableModels[randomIndex];

        // Only add if not already included
        if (!previewWatches.some((watch) => watch.symbol === selected.symbol)) {
          previewWatches.push(selected);
        }
        availableModels.splice(randomIndex, 1);
      }

      setSearchResults(previewWatches);
      setIsSearchVisible(true);
      await fetchPriceHistories(previewWatches);
    } catch (error) {
      console.error('Error fetching watch data:', error);
    }
  };

  const fetchPriceHistories = async (watches: SearchResult[]) => {
    const chartData: { [key: string]: ChartDataPoint[] } = {};
    const trendData: { [key: string]: TrendInfo } = {};

    for (const watch of watches) {
      try {
        const data = await fetchPriceHistory(watch.symbol);
        chartData[watch.symbol] = data;
        trendData[watch.symbol] = calculateTrend(data);
      } catch (error) {
        console.error(
          `Error fetching price history for ${watch.symbol}:`,
          error
        );
      }
    }

    setMiniCharts(chartData);
    setTrends(trendData);
  };

  const fetchPriceHistory = async (
    symbol: string
  ): Promise<ChartDataPoint[]> => {
    try {
      const rangeResponse = await fetch(
        `/api/trading-dashboard/getDataRange?symbol=${symbol}&source=forum`
      );
      const range = await rangeResponse.json();

      const response = await fetch(
        `/api/trading-dashboard/getBars?symbol=${symbol}&from=${range.firstDataPoint}&to=${range.lastDataPoint}&simple=true&source=forum`
      );
      const data = await response.json();

      return data
        .slice(-30)
        .filter((_: any, index: number) => index % 2 === 0)
        .map((item: { t: number; p: number }) => ({
          time: item.t,
          price: item.p,
        }));
    } catch (error) {
      console.error('Error fetching price history:', error);
      return [];
    }
  };

  const calculateTrend = (data: ChartDataPoint[]): TrendInfo => {
    if (data.length < 2) {
      return { isPositive: true, changePercent: 0, startPrice: 0, endPrice: 0 };
    }
    const startPrice = data[0].price;
    const endPrice = data[data.length - 1].price;
    const changePercent = ((endPrice - startPrice) / startPrice) * 100;
    return {
      isPositive: changePercent >= 0,
      changePercent,
      startPrice,
      endPrice,
    };
  };

  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      if (!datafeed || !datafeed.searchSymbols) {
        console.error('Datafeed not properly initialized');
        return;
      }

      setIsLoading(true);
      if (query.trim() === '') {
        setIsLoading(false);
        setPreviewIndex(0);
        return;
      }

      try {
        datafeed.searchSymbols(
          query,
          '',
          '',
          async (symbols: SearchResult[]) => {
            // Deduplicate search results based on symbol
            const uniqueSymbols = Array.from(
              new Map(symbols.map((item) => [item.symbol, item])).values()
            );

            let processedSymbols = uniqueSymbols.slice(0, EXACT_RESULTS);

            setSearchResults(processedSymbols);
            await fetchPriceHistories(processedSymbols);
            setIsLoading(false);
          }
        );
      } catch (error) {
        console.error('Search error:', error);
        setSearchResults([]);
        setIsLoading(false);
      }
    }, 300),
    [datafeed]
  );

  const handleUserInteraction = useCallback(() => {
    setUserInteracting(true);
    setAutoScrollEnabled(false);
    setPlaceholder(INITIAL_PLACEHOLDER);
    setIsTyping(false);

    if (interactionTimer) {
      clearTimeout(interactionTimer);
    }

    const timer = setTimeout(() => {
      setUserInteracting(false);
      setAutoScrollEnabled(true);
      setPreviewIndex(0);
    }, 10000);

    setInteractionTimer(timer);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      handleUserInteraction();
      debouncedSearch(value);
    },
    [debouncedSearch, handleUserInteraction]
  );

  const getFormattedWatchName = (result: SearchResult): string => {
    const brand = result.symbol.split(':')[0];
    const model = result.symbol.split(':')[1];
    return result.subModel
      ? `${brand} ${result.subModel} ${model}`
      : `${brand} ${model}`;
  };

  const getModelNumber = (symbol: string): string => symbol.split(':')[1];

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const animateTyping = async (text: string): Promise<void> => {
    try {
      setPlaceholder('');
      await sleep(CLEAR_PAUSE);

      for (let i = 0; i <= text.length; i++) {
        if (userInteracting) break;
        setPlaceholder(text.slice(0, i));
        await sleep(TYPING_SPEED);
      }

      if (!userInteracting) {
        await sleep(DISPLAY_DURATION);
      }
    } catch (error) {
      console.error('Animation error:', error);
    }
  };

  // Initialize data
  useEffect(() => {
    fetchWatchData();
    return () => {
      if (interactionTimer) {
        clearTimeout(interactionTimer);
      }
    };
  }, []);

  // Animation controller
  useEffect(() => {
    if (searchResults.length === 0 || userInteracting) return;

    let mounted = true;
    let currentIndex = 0;

    const runAnimation = async () => {
      await sleep(DELAY_BEFORE_START);

      while (mounted && !userInteracting) {
        const result = searchResults[currentIndex];
        await animateTyping(getFormattedWatchName(result));

        if (!mounted || userInteracting) break;

        currentIndex = (currentIndex + 1) % searchResults.length;
        setPreviewIndex(currentIndex);
      }
    };

    runAnimation();

    return () => {
      mounted = false;
    };
  }, [searchResults, userInteracting]);

  // Preview scroller
  useEffect(() => {
    if (!autoScrollEnabled || searchResults.length === 0 || userInteracting)
      return;

    const interval = setInterval(() => {
      setPreviewIndex((prev) => (prev + 1) % searchResults.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [searchResults.length, autoScrollEnabled, userInteracting]);

  return (
    <div className="relative w-full md:w-96">
      <Input
        className="w-full bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 
                   rounded-xl px-4 py-2 text-sm text-slate-200
                   hover:border-blue-500/30 focus:ring-2 focus:ring-blue-500/20
                   transition-all duration-300"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => {
          setIsSearchVisible(true);
          handleUserInteraction();
        }}
      />
      <AnimatePresence>
        {isSearchVisible && (searchResults.length > 0 || isLoading) && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute w-full mt-2 z-50"
          >
            <Card className="bg-slate-800/90 backdrop-blur-xl border-slate-700/50 shadow-2xl rounded-xl overflow-hidden">
              {isLoading ? (
                <div className="p-4 text-center text-slate-400">
                  <motion.div
                    animate={{ opacity: [0.5, 1, 0.5] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                  >
                    Searching...
                  </motion.div>
                </div>
              ) : (
                <ul className="divide-y divide-slate-700/30">
                  {searchResults.map((result, index) => (
                    <motion.li
                      key={result.symbol}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{
                        opacity:
                          !userInteracting && index === previewIndex ? 1 : 0.8,
                        x: 0,
                        scale:
                          !userInteracting && index === previewIndex ? 1 : 0.98,
                      }}
                      transition={{ duration: 0.3 }}
                      className="p-4 hover:bg-slate-700/30 cursor-pointer transition-all duration-300"
                      onClick={() => {
                        onSymbolSelect(result);
                        setInputValue(getFormattedWatchName(result));
                        setIsSearchVisible(false);
                      }}
                    >
                      <div className="flex items-start gap-4">
                        {' '}
                        {/* Changed from items-center to items-start */}
                        <div className="flex-shrink-0 mt-1">
                          {' '}
                          {/* Added mt-1 */}
                          <RolexImage
                            modelNumber={getModelNumber(result.symbol)}
                            size={48}
                            className="rounded-full transition-transform duration-300 hover:scale-110"
                          />
                        </div>
                        <div className="flex-grow min-w-0">
                          {' '}
                          {/* Added min-w-0 */}
                          <div className="font-medium text-slate-200 break-words">
                            {' '}
                            {/* Changed to break-words */}
                            {getFormattedWatchName(result)}
                          </div>
                          <div className="text-sm text-slate-400 flex items-center gap-2 flex-wrap">
                            {' '}
                            {/* Added flex-wrap */}
                            <span className="break-all">{result.exchange}</span>
                            {trends[result.symbol] && (
                              <span
                                className={`flex items-center gap-1 text-xs font-medium ${
                                  trends[result.symbol].isPositive
                                    ? 'text-emerald-400'
                                    : 'text-rose-400'
                                }`}
                              >
                                {trends[result.symbol].isPositive ? (
                                  <ArrowUpCircle className="w-3 h-3" />
                                ) : (
                                  <ArrowDownCircle className="w-3 h-3" />
                                )}
                                {Math.abs(
                                  trends[result.symbol].changePercent
                                ).toFixed(1)}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                        {miniCharts[result.symbol] && (
                          <div className="w-32 h-16">
                            <ResponsiveContainer width="100%" height="100%">
                              <LineChart data={miniCharts[result.symbol]}>
                                <defs>
                                  <linearGradient
                                    id={`gradient-${result.symbol}`}
                                    x1="0"
                                    y1="0"
                                    x2="1"
                                    y2="0"
                                  >
                                    <stop offset="0%" stopColor="#3b82f6" />
                                    <stop
                                      offset="100%"
                                      stopColor={
                                        trends[result.symbol]?.isPositive
                                          ? '#34d399'
                                          : '#fb7185'
                                      }
                                    />
                                  </linearGradient>
                                </defs>
                                <Line
                                  type="monotone"
                                  dataKey="price"
                                  stroke={`url(#gradient-${result.symbol})`}
                                  dot={false}
                                  strokeWidth={2}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          </div>
                        )}
                      </div>
                    </motion.li>
                  ))}
                </ul>
              )}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeroSearch;
