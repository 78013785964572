//navbar.tsx
'use client';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { AvatarImage, Avatar } from '@/components/ui/avatar';
import Link from 'next/link';
import { useAuth } from '../../../lib/AuthContext';
import { usePathname, useRouter } from 'next/navigation';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import {
  Bell as BellIcon,
  ShoppingBag as ShoppingBagIcon,
  Menu as MenuIcon,
  X as XIcon,
  LayoutDashboard,
  Wallet,
  LineChart,
  BookOpen,
  Info,
} from 'lucide-react';
import { ProfilePanel } from './ProfilePanel';
import { useCart } from '../../../contexts/CartContext';

interface Navbar1Props {
  isSticky?: boolean;
  onProfilePanelChange?: (isOpen: boolean) => void;
  isTransparent?: boolean;
}

export function Navbar1({
  isSticky,
  onProfilePanelChange,
  isTransparent = false,
}: Navbar1Props) {
  const router = useRouter();
  const { user } = useAuth();
  const { cartItems } = useCart();
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState(pathname);

  // Update activeTab when pathname changes
  useEffect(() => {
    setActiveTab(pathname);
  }, [pathname]);

  const navigationItems = [
    {
      name: 'Marketplace',
      path: '/marketplace',
      icon: ShoppingBagIcon,
      description: 'Browse luxury watches',
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: LayoutDashboard,
      description: 'View your activity',
    },
    {
      name: 'Portfolio',
      path: '/portfolio',
      icon: Wallet,
      description: 'Manage your collection',
    },
    {
      name: 'Charts',
      path: '/charts',
      icon: LineChart,
      description: 'Market analytics',
    },
    {
      name: 'Blog',
      path: '/blog',
      icon: BookOpen,
      description: 'News and articles',
    },
    {
      name: 'About',
      path: '/about',
      icon: Info,
      description: 'Learn about us',
    },
  ];

  const handleNavigation = (path: string, e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(path, '_blank');
    } else {
      router.push(path);
      setIsMobileMenuOpen(false);
      setIsProfilePanelOpen(false);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey || e.metaKey) {
      return;
    }
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    if (href) {
      router.push(href);
      setIsMobileMenuOpen(false);
      setIsProfilePanelOpen(false);
    }
  };

  useEffect(() => {
    onProfilePanelChange?.(isProfilePanelOpen);
  }, [isProfilePanelOpen, onProfilePanelChange]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsProfilePanelOpen(false);
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest('.profile-panel') &&
        !target.closest('.profile-trigger')
      ) {
        setIsProfilePanelOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const CartBadge = () => {
    const badgeCount = cartItems.length;
    if (badgeCount === 0) return null;

    return (
      <motion.span
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="absolute -top-2 -right-2 bg-blue-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
      >
        {badgeCount}
      </motion.span>
    );
  };

  return (
    <nav
      className={`text-gray-200 relative shadow-sm transition-all duration-300
        ${isSticky ? 'sticky top-0 z-[100]' : ''}
        ${isTransparent ? '' : 'bg-slate-900/70 backdrop-blur-lg backdrop-saturate-150'}
      `}
    >
      {/* Desktop Layout */}
      <div className="hidden md:block">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-14">
            {/* Logo - Fixed glow effect */}
            <div className="w-[200px]">
              <Link
                href="/"
                className="relative inline-block group"
                onClick={handleLinkClick}
              >
                <div className="absolute inset-0 rounded-full blur-sm opacity-0 group-hover:opacity-20 transition duration-200 bg-gradient-to-r from-blue-600 to-blue-400"></div>
                <Image
                  src="/watchinspect.svg"
                  width={60}
                  height={60}
                  alt="Watch Inspect Logo"
                  className="relative transform transition-transform duration-200 group-hover:scale-105"
                  style={{ objectFit: 'contain', height: 'auto' }}
                />
              </Link>
            </div>

            {/* Desktop Navigation - Center aligned with flex-grow */}
            <div className="flex-grow flex justify-center">
              <div className="flex items-center space-x-1">
                {navigationItems.map((item) => (
                  <Button
                    key={item.name}
                    variant="ghost"
                    onClick={(e: React.MouseEvent) =>
                      handleNavigation(item.path, e)
                    }
                    className={`
                      text-gray-200 hover:text-blue-400 hover:bg-white/10 
                      transition-all duration-200 px-4 py-2 rounded-md relative
                      ${activeTab === item.path ? 'text-blue-400 bg-white/5' : ''}
                    `}
                  >
                    {item.name}
                    {activeTab === item.path && (
                      <motion.div
                        layoutId="activeTab"
                        className="absolute -bottom-1 left-0 right-0 mx-auto w-12 h-0.5 bg-blue-400"
                        transition={{
                          type: 'spring',
                          stiffness: 500,
                          damping: 30,
                        }}
                      />
                    )}
                  </Button>
                ))}
              </div>
            </div>

            {/* Desktop Right Section - Fixed width to match logo */}
            <div className="w-[200px] flex items-center justify-end space-x-4">
              <Button
                variant="ghost"
                className="relative group hover:bg-white/10"
              >
                <BellIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400" />
              </Button>

              <Link href="/checkout" onClick={handleLinkClick}>
                <Button
                  variant="ghost"
                  className="relative group hover:bg-white/10"
                >
                  <ShoppingBagIcon className="h-5 w-5 text-gray-200 group-hover:text-blue-400" />
                  <CartBadge />
                </Button>
              </Link>

              {user ? (
                <div className="relative profile-trigger">
                  <Button
                    variant="ghost"
                    onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                    className="group hover:bg-white/10"
                  >
                    <Avatar className="h-8 w-8 ring-2 ring-blue-500/50">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || '/reddit.webp'}
                        className="object-cover"
                      />
                    </Avatar>
                  </Button>
                  <AnimatePresence mode="wait">
                    {isProfilePanelOpen && (
                      <>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[999]"
                          onClick={() => setIsProfilePanelOpen(false)}
                        />
                        <motion.div
                          initial={{ x: '100%' }}
                          animate={{ x: 0 }}
                          exit={{ x: '100%' }}
                          transition={{
                            type: 'spring',
                            stiffness: 300,
                            damping: 30,
                          }}
                          className="fixed right-0 top-0 w-[400px] h-screen z-[1000]"
                        >
                          <ProfilePanel
                            onClose={() => setIsProfilePanelOpen(false)}
                          />
                        </motion.div>
                      </>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link href="/auth" onClick={handleLinkClick}>
                  <Button className="bg-blue-600 hover:bg-blue-700">
                    Login
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="md:hidden">
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-700/50">
          <Link href="/" onClick={handleLinkClick}>
            <div className="relative inline-block group">
              <div className="absolute inset-0 rounded-full blur-sm opacity-0 group-hover:opacity-20 transition duration-200 bg-gradient-to-r from-blue-600 to-blue-400"></div>
              <Image
                src="/watchinspect.svg"
                width={40}
                height={40}
                alt="Watch Inspect Logo"
                className="relative transition-transform duration-200 group-hover:scale-105"
              />
            </div>
          </Link>
          <div className="flex items-center space-x-4">
            {user && (
              <div className="relative profile-trigger">
                <Button
                  variant="ghost"
                  onClick={() => setIsProfilePanelOpen(!isProfilePanelOpen)}
                  className="relative group p-2 hover:bg-white/10"
                >
                  <Avatar className="h-8 w-8 ring-2 ring-blue-500/50 transition-all duration-200 group-hover:ring-blue-400">
                    <AvatarImage
                      alt="User avatar"
                      src={user?.profilePicture || '/reddit.webp'}
                      className="object-cover"
                    />
                  </Avatar>
                </Button>
              </div>
            )}

            <Button
              variant="ghost"
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
                setIsProfilePanelOpen(false);
              }}
              className="p-2 hover:bg-white/10 transition-colors duration-200"
            >
              {isMobileMenuOpen ? (
                <XIcon className="h-6 w-6 text-gray-200" />
              ) : (
                <MenuIcon className="h-6 w-6 text-gray-200" />
              )}
            </Button>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="fixed inset-0 bg-black/95 backdrop-blur-md z-50"
            >
              {/* Menu Header */}
              <div className="flex items-center justify-between px-4 py-3 border-b border-gray-800">
                <Link href="/" onClick={handleLinkClick}>
                  <Image
                    src="/watchinspect.svg"
                    width={40}
                    height={40}
                    alt="Watch Inspect Logo"
                    className="transition-transform duration-200 hover:scale-105"
                  />
                </Link>
                <Button
                  variant="ghost"
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 hover:bg-white/10"
                >
                  <XIcon className="h-6 w-6 text-gray-200" />
                </Button>
              </div>

              {/* Menu Content */}
              <div className="px-4 py-6 space-y-6 overflow-y-auto max-h-[calc(100vh-4rem)]">
                {/* User Section */}
                {user && (
                  <div className="flex items-center space-x-4 p-4 bg-gray-900/50 rounded-lg">
                    <Avatar className="h-12 w-12 ring-2 ring-blue-500/50">
                      <AvatarImage
                        alt="User avatar"
                        src={user?.profilePicture || '/reddit.webp'}
                        className="object-cover"
                      />
                    </Avatar>
                    <div>
                      <p className="text-sm font-medium text-gray-200">
                        {user.name || 'User'}
                      </p>
                      <p className="text-xs text-gray-400">{user.email}</p>
                    </div>
                  </div>
                )}

                {/* Navigation Links */}
                <div className="space-y-2">
                  {navigationItems.map((item, index) => (
                    <motion.button
                      key={item.name}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.05 }}
                      onClick={(e: React.MouseEvent) =>
                        handleNavigation(item.path, e)
                      }
                      className={`
                        w-full flex items-center space-x-3 p-3 rounded-lg
                        transition-all duration-200
                        ${
                          activeTab === item.path
                            ? 'bg-blue-600/20 text-blue-400'
                            : 'text-gray-200 hover:bg-white/5'
                        }
                      `}
                    >
                      <div
                        className={`
                          flex-shrink-0 p-2 rounded-lg
                          ${
                            activeTab === item.path
                              ? 'bg-blue-500/20'
                              : 'bg-gray-800/50'
                          }
                        `}
                      >
                        <item.icon className="h-5 w-5" />
                      </div>
                      <div className="flex flex-col items-start">
                        <span className="text-sm font-medium">{item.name}</span>
                        <span className="text-xs text-gray-400">
                          {item.description}
                        </span>
                      </div>
                    </motion.button>
                  ))}
                </div>

                {/* Quick Actions */}
                <div className="flex items-center justify-around p-4 bg-gray-900/50 rounded-lg">
                  <Button
                    variant="ghost"
                    className="relative group p-3 hover:bg-white/10"
                  >
                    <BellIcon className="h-6 w-6 text-gray-200 group-hover:text-blue-400" />
                  </Button>

                  <Link href="/checkout" onClick={handleLinkClick}>
                    <Button
                      variant="ghost"
                      className="relative group p-3 hover:bg-white/10"
                    >
                      <ShoppingBagIcon className="h-6 w-6 text-gray-200 group-hover:text-blue-400" />
                      <CartBadge />
                    </Button>
                  </Link>

                  {!user && (
                    <Link href="/auth" onClick={handleLinkClick}>
                      <Button className="bg-blue-600 hover:bg-blue-700 px-6">
                        Login
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Mobile Profile Panel */}
        <AnimatePresence mode="wait">
          {isProfilePanelOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[9998]"
                onClick={() => setIsProfilePanelOpen(false)}
              />
              <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 30,
                }}
                className="fixed right-0 top-0 w-[400px] h-screen z-[9999]"
              >
                <ProfilePanel onClose={() => setIsProfilePanelOpen(false)} />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}
