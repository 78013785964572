// price-tracking-hero.tsx

import React, { useState, useEffect, ReactNode } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { ArrowUpCircle, ArrowDownCircle, Loader2 } from 'lucide-react';
import GlowButton from './glow-button';
import HeroSearch from './hero-search';
import { SimpleDatafeed } from '../dashboard/tradingview-utils/SimpleDatafeed';
import { SearchResult } from '../dashboard/watchlist/watchlist.types';
import { RolexImage } from '../../../../utils/RolexImage'; // Add this import

interface GlowEffectContainerProps {
  children: ReactNode;
}

interface PriceDataPoint {
  date: string;
  price: number;
  time: number;
}

interface ApiResponse {
  t: number;
  p: number;
}

const GlowEffectContainer: React.FC<GlowEffectContainerProps> = ({
  children,
}) => {
  return (
    <div className="relative w-full">
      <div className="absolute inset-0 pointer-events-none mix-blend-lighten">
        <svg
          viewBox="0 0 1920 1080"
          className="w-full h-full absolute inset-0"
          preserveAspectRatio="xMidYMid slice"
        >
          <defs>
            <radialGradient id="beamGradient" cx="0.5" cy="0.5" r="0.5">
              <stop offset="0%" stopColor="#60A5FA" stopOpacity="1" />
              <stop offset="100%" stopColor="#60A5FA" stopOpacity="0" />
            </radialGradient>
            <linearGradient id="edgeGlow" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.5" />
              <stop offset="50%" stopColor="#60A5FA" stopOpacity="0" />
              <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.5" />
            </linearGradient>
            <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur stdDeviation="30" result="blur" />
              <feMerge>
                <feMergeNode in="blur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <mask id="beamMask">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="white"
                opacity="0.9"
              />
            </mask>
          </defs>

          <g filter="url(#glow)" mask="url(#beamMask)">
            <rect
              x="48%"
              y="0"
              width="4%"
              height="100%"
              fill="url(#beamGradient)"
              opacity="0.7"
            >
              <animate
                attributeName="opacity"
                values="0;0.7;0.7;0"
                dur="4s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="height"
                values="0%;100%;100%;100%"
                dur="4s"
                repeatCount="indefinite"
              />
            </rect>
          </g>

          <g filter="url(#glow)" opacity="0">
            <animate
              attributeName="opacity"
              values="0;0;0.7;0.4"
              dur="4s"
              repeatCount="indefinite"
            />
            <path
              d="M 0 600 Q 200 600 200 700 L 200 900 Q 200 1000 0 1000 Z"
              fill="url(#edgeGlow)"
            />
            <path
              d="M 1920 600 Q 1720 600 1720 700 L 1720 900 Q 1720 1000 1920 1000 Z"
              fill="url(#edgeGlow)"
            />
          </g>

          <radialGradient id="bottomGlow" cx="0.5" cy="1" r="0.7">
            <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.3" />
            <stop offset="100%" stopColor="#60A5FA" stopOpacity="0" />
          </radialGradient>
          <ellipse
            cx="50%"
            cy="100%"
            rx="60%"
            ry="40%"
            fill="url(#bottomGlow)"
            opacity="0"
          >
            <animate
              attributeName="opacity"
              values="0;0;0.5;0.3"
              dur="4s"
              repeatCount="indefinite"
            />
          </ellipse>
        </svg>
      </div>

      <div className="relative z-10">{children}</div>

      <div className="absolute -inset-10 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-t from-blue-500/10 via-transparent to-transparent blur-3xl opacity-50" />
        <div className="absolute -left-10 -right-10 bottom-0 h-[500px] bg-gradient-to-t from-blue-500/20 via-blue-500/5 to-transparent blur-2xl" />
      </div>
    </div>
  );
};

const applySmoothing = (
  data: PriceDataPoint[],
  period: number = 20
): PriceDataPoint[] => {
  return data.map((point, index) => {
    if (index < period) return point;

    const window = data.slice(Math.max(0, index - period + 1), index + 1);
    const weightedSum = window.reduce((sum, p, i) => {
      const weight = Math.exp(i / window.length);
      return sum + p.price * weight;
    }, 0);
    const weights = window.reduce(
      (sum, _, i) => sum + Math.exp(i / window.length),
      0
    );

    return {
      ...point,
      price: Number((weightedSum / weights).toFixed(2)),
    };
  });
};

const fetchPriceData = async (
  symbol: string = 'Rolex:16610'
): Promise<PriceDataPoint[]> => {
  try {
    const rangeResponse = await fetch(
      `/api/trading-dashboard/getDataRange?symbol=${symbol}&source=forum`
    );
    const range = await rangeResponse.json();

    const response = await fetch(
      `/api/trading-dashboard/getBars?symbol=${symbol}&from=${range.firstDataPoint}&to=${range.lastDataPoint}&simple=true&source=forum`
    );
    const data: ApiResponse[] = await response.json();

    const transformedData = data
      .map((item) => ({
        date: new Date(item.t).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric',
        }),
        price: Number(item.p.toFixed(2)),
        time: item.t,
      }))
      .sort((a, b) => a.time - b.time);

    return applySmoothing(transformedData, 20);
  } catch (error) {
    console.error('Error fetching price data:', error);
    return [];
  }
};

const PriceTrackingHero: React.FC<{ datafeed?: any }> = ({ datafeed }) => {
  const [priceData, setPriceData] = useState<PriceDataPoint[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSymbol, setSelectedSymbol] = useState('Rolex:16610');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [selectedWatchInfo, setSelectedWatchInfo] = useState<{
    brand: string;
    model: string;
    subModel?: string;
  }>({
    brand: 'Rolex',
    model: '16610',
    subModel: 'Submariner',
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // 640px is Tailwind's sm breakpoint
    };

    // Check initially
    checkMobile();

    // Add resize listener
    window.addEventListener('resize', checkMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const getModelNumber = (symbol: string): string => {
    if (symbol.includes(':')) {
      return symbol.split(':')[1];
    }
    return symbol;
  };
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchPriceData(selectedSymbol);
        setPriceData(data);
      } catch (error) {
        console.error('Error loading price data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, [selectedSymbol]);

  const handleSymbolSelect = (result: SearchResult) => {
    setSelectedSymbol(result.symbol);
    const [brand, model] = result.symbol.split(':');
    setSelectedWatchInfo({
      brand,
      model,
      subModel: result.subModel,
    });
    setSearchResults((prev) => [...prev, result]);
  };

  const currentPrice =
    !isLoading && priceData.length > 0
      ? Number((priceData[priceData.length - 1]?.price || 0).toFixed(2))
      : null;
  const previousPrice =
    !isLoading && priceData.length > 1
      ? Number((priceData[priceData.length - 2]?.price || 0).toFixed(2))
      : null;
  const priceChange =
    currentPrice && previousPrice
      ? Number((currentPrice - previousPrice).toFixed(2))
      : null;
  const percentChange =
    currentPrice && previousPrice && priceChange !== null
      ? Number(((priceChange / previousPrice) * 100).toFixed(1))
      : null;
  const isPositive = priceChange ? priceChange >= 0 : null;

  const getFormattedWatchName = () => {
    const { brand, model, subModel } = selectedWatchInfo;
    return subModel ? `${brand} ${subModel} ${model}` : `${brand} ${model}`;
  };

  const renderPriceInfo = () => {
    if (isLoading) {
      return (
        <div className="flex items-center gap-2">
          <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
          <span className="text-slate-400">Loading price data...</span>
        </div>
      );
    }

    return (
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-3">
        <span className="text-2xl sm:text-3xl font-bold text-white">
          $
          {currentPrice?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
        {priceChange !== null && percentChange !== null && (
          <div
            className={`flex items-center gap-1 ${
              isPositive ? 'text-emerald-400' : 'text-rose-400'
            }`}
          >
            {isPositive ? (
              <ArrowUpCircle className="w-5 h-5 sm:w-6 sm:h-6" />
            ) : (
              <ArrowDownCircle className="w-5 h-5 sm:w-6 sm:h-6" />
            )}
            <span className="text-sm sm:text-base font-medium">
              $
              {Math.abs(priceChange).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span className="text-sm sm:text-base">({percentChange}%)</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative w-full min-h-[90vh] bg-slate-950">
      <GlowEffectContainer>
        <div className="flex flex-col items-center justify-center px-4 py-8 sm:py-12 md:py-16">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-950/20 via-slate-950 to-slate-950" />
          <div className="absolute inset-0">
            <div className="absolute top-0 -right-1/4 w-1/2 h-1/2 bg-blue-500/10 blur-[120px] rounded-full" />
            <div className="absolute bottom-0 -left-1/4 w-1/2 h-1/2 bg-blue-500/10 blur-[120px] rounded-full" />
          </div>

          <div className="relative z-10 w-full max-w-[1200px] mx-auto">
            {/* Hero Text Section */}
            <div
              className={`space-y-6 sm:space-y-8 text-center transform transition-all duration-1000 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white tracking-tight">
                Track Watch Prices
                <br />
                <span className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 text-transparent bg-clip-text">
                  Like Never Before
                </span>
              </h1>
              <p className="text-base sm:text-lg md:text-xl text-slate-300 max-w-2xl mx-auto font-light">
                Real-time market data from every major marketplace, helping you
                make informed decisions
              </p>

              <div className="flex justify-center mt-6 sm:mt-8">
                <GlowButton className="w-full max-w-xs sm:w-auto px-6 sm:px-8 py-3 text-sm sm:text-base" />
              </div>
            </div>

            {/* Chart Section */}
            <div
              className={`mt-8 sm:mt-12 md:mt-16 transform transition-all duration-1000 delay-300 ${
                isVisible
                  ? 'translate-y-0 opacity-100'
                  : 'translate-y-10 opacity-0'
              }`}
            >
              <div className="chart-container relative bg-slate-900/50 backdrop-blur-sm rounded-2xl p-4 sm:p-6 md:p-8 border border-slate-800/50">
                <div className="chart-glow absolute -inset-[1px] bg-gradient-to-r from-blue-500/0 via-blue-500/10 to-blue-500/0 rounded-2xl" />
                <div className="relative">
                  {/* Main Info Section - Updated Grid Layout */}
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 sm:gap-6 md:gap-8 mb-8">
                    {/* Watch Info - Adjusted for longer text */}
                    <div className="lg:col-span-4">
                      <div className="flex items-start gap-4">
                        {' '}
                        {/* Changed from items-center to items-start */}
                        <div className="relative w-12 sm:w-16 h-12 sm:h-16 transform transition-all duration-300 hover:scale-110 flex-shrink-0 mt-1">
                          {' '}
                          {/* Added mt-1 */}
                          <RolexImage
                            modelNumber={selectedSymbol.split(':')[1]}
                            size={64}
                            className="rounded-full shadow-lg"
                          />
                        </div>
                        <div className="min-w-0 flex-grow">
                          <h3 className="text-xl sm:text-2xl font-bold text-white mb-2 break-words">
                            {' '}
                            {/* Changed from truncate to break-words */}
                            {getFormattedWatchName()}
                          </h3>
                          {renderPriceInfo()}
                        </div>
                      </div>
                    </div>

                    {/* Search - Moved down slightly */}
                    <div className="lg:col-span-4 lg:pt-2">
                      {' '}
                      {/* Added pt-2 for slight padding top */}
                      <HeroSearch
                        onSymbolSelect={handleSymbolSelect}
                        datafeed={new SimpleDatafeed()}
                      />
                    </div>

                    {/* Stats - Adjusted alignment */}
                    <div className="lg:col-span-4 lg:pt-2">
                      {' '}
                      {/* Added pt-2 to match search */}
                      <div className="grid grid-cols-3 gap-3 sm:gap-4">
                        <div className="text-center ml-4 p-2 sm:p-3 md:p-4 bg-slate-800/50 rounded-xl border border-slate-700/30">
                          <div className="text-xs sm:text-sm text-slate-400">
                            Daily Volume
                          </div>
                          <div className="text-base sm:text-lg md:text-2xl font-bold text-white">
                            142
                          </div>
                        </div>
                        <div className="text-center p-2 sm:p-3 md:p-4 bg-slate-800/50 rounded-xl border border-slate-700/30">
                          <div className="text-xs sm:text-sm text-slate-400">
                            Listings
                          </div>
                          <div className="text-base sm:text-lg md:text-2xl font-bold text-white">
                            3.2K
                          </div>
                        </div>
                        <div className="text-center p-2 sm:p-3 md:p-4 bg-slate-800/50 rounded-xl border border-slate-700/30">
                          <div className="text-xs sm:text-sm text-slate-400">
                            Sources
                          </div>
                          <div className="text-base sm:text-lg md:text-2xl font-bold text-white">
                            12
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Chart */}
                  <div className="h-[300px] sm:h-[400px] relative">
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-500/5 to-transparent rounded-2xl" />
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={priceData}
                        margin={
                          isMobile
                            ? { left: 0, right: 0, top: 10, bottom: 0 }
                            : { left: 20, right: 20 }
                        }
                      >
                        <defs>
                          <linearGradient
                            id="colorPrice"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#3b82f6"
                              stopOpacity={0.3}
                            />
                            <stop
                              offset="95%"
                              stopColor="#3b82f6"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="date"
                          stroke="#64748b"
                          tickLine={false}
                          axisLine={false}
                          tick={{ fill: '#94a3b8' }}
                          interval="preserveStartEnd"
                          minTickGap={30}
                          ticks={[
                            priceData[0]?.date || '',
                            isMobile
                              ? ''
                              : priceData[Math.floor(priceData.length / 2)]
                                  ?.date || '',
                            priceData[priceData.length - 1]?.date || '',
                          ]}
                          padding={
                            isMobile
                              ? { left: 0, right: 0 }
                              : { left: 30, right: 30 }
                          }
                          fontSize={14}
                          dy={isMobile ? 10 : 0}
                        />

                        <YAxis
                          stroke="#64748b"
                          tickLine={false}
                          axisLine={false}
                          tick={{ fill: '#94a3b8' }}
                          tickFormatter={(value) =>
                            `$${(value / 1000).toFixed(1)}k`
                          }
                          width={isMobile ? 55 : 60} // Increased from 45
                          fontSize={14} // Increased from 10
                          dx={isMobile ? -8 : 0}
                        />
                        <CartesianGrid
                          stroke="#2d374850"
                          strokeDasharray="3 3"
                          vertical={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="price"
                          stroke="#3b82f6"
                          strokeWidth={2}
                          dot={false}
                          activeDot={{
                            r: isMobile ? 6 : 8,
                            fill: '#3b82f6',
                            strokeWidth: 0,
                          }}
                          fillOpacity={1}
                          fill="url(#colorPrice)"
                        />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: '#1e293b',
                            border: 'none',
                            borderRadius: '0.75rem',
                            boxShadow: '0 0 20px rgba(59, 130, 246, 0.1)',
                            color: '#f8fafc',
                            padding: isMobile ? '12px' : '12px',
                            fontSize: isMobile ? '14px' : '14px',
                          }}
                          formatter={(value: number) => [
                            <span
                              key="tooltip"
                              style={{
                                display: 'flex',
                                gap: '4px',
                                fontSize: '14px',
                              }}
                            >
                              <span style={{ color: '#3b82f6' }}>
                                $
                                {value.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </span>,
                            '',
                          ]}
                          labelFormatter={(label) => (
                            <span style={{ color: '#ffffff' }}>{label}</span>
                          )}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GlowEffectContainer>

      <style jsx>{`
        .chart-container {
          box-shadow: 0 0 50px 2px rgba(59, 130, 246, 0.1);
        }

        .chart-glow {
          filter: blur(20px);
        }

        .glow-btn {
          box-shadow: 0 0 20px rgba(59, 130, 246, 0.3);
        }
        .glow-line {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(
            90deg,
            transparent 0%,
            rgba(59, 130, 246, 0.8) 50%,
            transparent 100%
          );
          opacity: 0;
          transform: translateX(-100%);
          transition: opacity 0.3s;
        }

        .glow-btn:hover .glow-line {
          animation: glow-slide 1s infinite;
          opacity: 0.3;
        }

        @keyframes glow-slide {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      `}</style>
    </div>
  );
};

export default PriceTrackingHero;
