// home/hero-front.tsx
import { useEffect } from 'react';
import PriceTrackingHero from '../../component/home/price-tracking-hero';

export function HeroFront({
  setIsSticky,
}: {
  setIsSticky: (value: boolean) => void;
}) {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 100); // Adjust threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setIsSticky]);

  return (
    <section className="relative -mt-14">
      {' '}
      {/* Negative margin to pull content up */}
      <PriceTrackingHero />
    </section>
  );
}
