//RolexImage.tsx
import { useState, useEffect } from 'react';

// Global image cache
const IMAGE_CACHE: Record<string, string> = {};
const IMAGE_LOADING_CACHE: Record<string, Promise<string>> = {};

const getModelNumber = (symbol: string): string => {
  // For debugging
  console.log('getModelNumber input:', symbol);

  // If it's already a clean model number
  if (/^\d{5,6}(?:[A-Z]{2})?$/.test(symbol)) {
    return symbol;
  }

  // If it's in name:"Rolex:MODEL" format
  if (typeof symbol === 'string' && symbol.includes(':')) {
    const [brand, model] = symbol.split(':');
    if (brand.toUpperCase() === 'ROLEX') {
      // Clean up the model number
      return model.replace('Rolex ', '').replace('ROLEX:', '').trim();
    }
  }

  // If it starts with 'Rolex '
  if (symbol.startsWith('Rolex ')) {
    return symbol.replace('Rolex ', '').trim();
  }

  // Remove any other prefixes and clean up
  return symbol
    .replace('ROLEX:', '')
    .replace('Rolex:', '')
    .replace('WatchInspect:', '')
    .replace('Rolex ', '')
    .trim();
};
const loadImage = async (modelNumber: string): Promise<string> => {
  const cleanModelNumber = getModelNumber(modelNumber);
  console.log('Loading image for model:', cleanModelNumber);

  // If image is already cached, return it immediately
  if (IMAGE_CACHE[cleanModelNumber]) {
    console.log('Using cached image:', IMAGE_CACHE[cleanModelNumber]);
    return IMAGE_CACHE[cleanModelNumber];
  }

  // If this image is currently loading, return the existing promise
  const existingPromise = IMAGE_LOADING_CACHE[cleanModelNumber];
  if (existingPromise) {
    return existingPromise;
  }

  // Create a new loading promise
  const loadingPromise = (async () => {
    try {
      // Try to fetch the PNG first
      const pngPath = `/rolex/${cleanModelNumber}.png`;
      const response = await fetch(pngPath);

      if (response.ok) {
        IMAGE_CACHE[cleanModelNumber] = pngPath;
        return pngPath;
      }

      // If PNG doesn't exist, try SVG
      const svgPath = `/rolex/${cleanModelNumber}.svg`;
      const svgResponse = await fetch(svgPath);

      if (svgResponse.ok) {
        IMAGE_CACHE[cleanModelNumber] = svgPath;
        return svgPath;
      }

      // If neither exists, fall back to default
      IMAGE_CACHE[cleanModelNumber] = '/rolex1.svg';
      return '/rolex1.svg';
    } catch (error) {
      console.error('Error loading image:', error);
      IMAGE_CACHE[cleanModelNumber] = '/rolex1.svg';
      return '/rolex1.svg';
    } finally {
      // Clean up loading cache after resolution
      delete IMAGE_LOADING_CACHE[cleanModelNumber];
    }
  })();

  // Store the loading promise
  IMAGE_LOADING_CACHE[cleanModelNumber] = loadingPromise;
  return loadingPromise;
};

export const useRolexImage = (modelNumber: string) => {
  const cleanModelNumber = getModelNumber(modelNumber);
  const [imagePath, setImagePath] = useState<string>(
    () => IMAGE_CACHE[cleanModelNumber] || '/rolex1.svg'
  );
  const [isLoading, setIsLoading] = useState(!IMAGE_CACHE[cleanModelNumber]);

  useEffect(() => {
    if (!cleanModelNumber) return;

    // If image is already cached, use it immediately
    if (IMAGE_CACHE[cleanModelNumber]) {
      setImagePath(IMAGE_CACHE[cleanModelNumber]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let mounted = true;

    loadImage(cleanModelNumber).then((path) => {
      if (mounted) {
        setImagePath(path);
        setIsLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, [cleanModelNumber]);

  return { imagePath, isLoading };
};

export const RolexImage: React.FC<{
  modelNumber: string;
  className?: string;
  size?: number;
}> = ({ modelNumber, className = '', size = 40 }) => {
  const cleanModelNumber = getModelNumber(modelNumber);
  const { imagePath, isLoading } = useRolexImage(cleanModelNumber);

  return (
    <div
      className={`relative ${className}`}
      style={{ width: size, height: size }}
    >
      {isLoading ? (
        <div className="animate-pulse bg-slate-700 rounded-full w-full h-full" />
      ) : (
        <img
          src={imagePath}
          alt={`Rolex ${cleanModelNumber}`}
          className="w-full h-full object-contain rounded-full"
          loading="lazy"
          onError={(event) => {
            // Fallback to default image if loading fails
            const img = event.target as HTMLImageElement;
            if (img) {
              img.src = '/rolex1.svg';
              // Cache the fallback
              IMAGE_CACHE[cleanModelNumber] = '/rolex1.svg';
            }
          }}
        />
      )}
    </div>
  );
};
