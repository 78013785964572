//lib/AuthContext.tsx
'use client';

import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface User {
  id?: string;
  _id?: string;
  name?: string;
  email?: string;
  profilePicture?: string;
  [key: string]: any;
}

interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  logout: () => void;
  login?: (userData: { id: string;[key: string]: any }) => void;
  updateUserProfile: (updates: Partial<User>) => void;
}

const authContextDefaultValues: AuthContextType = {
  user: null,
  setUser: () => { },
  logout: () => { },
  updateUserProfile: () => { },
};

export const AuthContext = createContext<AuthContextType>(authContextDefaultValues);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const userData: User = JSON.parse(storedUser);
      if (userData._id && !userData.id) {
        userData.id = userData._id;
      }
      setUser(userData);
    }
  }, []);

  const login = (userData: { id: string;[key: string]: any }) => {
    const userWithIds: User = {
      ...userData,
      id: userData.id || userData._id,
      _id: userData._id || userData.id,
    };
    setUser(userWithIds);
    localStorage.setItem("user", JSON.stringify(userWithIds));
  };

  const updateUserProfile = (updates: Partial<User>) => {
    setUser((prev: User | null) => {
      if (!prev) return null;
      const updated = { ...prev, ...updates };
      localStorage.setItem("user", JSON.stringify(updated));
      return updated;
    });
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, updateUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);