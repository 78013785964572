//simpleDatafeed.ts
import { io, Socket } from 'socket.io-client';
import {
  SocketEvents,
  WebSocketUpdate,
  Bar,
  SymbolDataRange,
  WatchData,
  ISimpleDatafeed,
  ExtendedSearchSymbolResultItem, 
} from './types';
import {
  IBasicDataFeed,
  LibrarySymbolInfo,
  SearchSymbolResultItem,
  ResolutionString,
} from 'charting_library/charting_library';

export class SimpleDatafeed implements ISimpleDatafeed {
  public socket!: Socket;

  private subscribers: { [key: string]: (bar: Bar) => void } = {};
  private cachedBars: { [key: string]: Bar[] } = {};
  private symbolDataRanges: {
    [key: string]: { firstDataPoint: number; lastDataPoint: number };
  } = {};

  private symbolIcons: { [key: string]: string } = {
    ROLEX: '/dashboard/rolex1.svg',
    OMEGA: '/dashboard/omega1.svg',
    'METAL:GOLD': '/dashboard/gold.svg',
    'INDEX:SP500': '/dashboard/sp500.svg',
    'INFLATION:CPI': '/dashboard/us-flag.svg',
    'INFLATION:RATE': '/dashboard/us-flag.svg',
    'EMPLOYMENT:UNRATE': '/dashboard/us-flag.svg',
    'INTEREST:FED': '/dashboard/us-flag.svg',
    'MACRO:GDP': '/dashboard/us-flag.svg',
    'FED:BALANCE': '/dashboard/us-flag.svg',
    'MONEY:M2': '/dashboard/us-flag.svg',
    'MONEY:BASE': '/dashboard/us-flag.svg',
  };
  constructor() {
    console.log('Starting SimpleDatafeed initialization');

    // Initialize with a default URL
    const socketUrl = 'http://localhost:3000';

    try {
      // Explicitly create the socket with the fixed URL
      this.socket = io(socketUrl, {
        path: '/api/socketio',
        transports: ['websocket'],
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
      });

      // Log successful creation
      console.log('Socket instance created with URL:', socketUrl);

      // Set up initial connection handlers
      this.socket.on('connect', () => {
        console.log('Socket connected successfully:', {
          id: this.socket.id,
          url: socketUrl,
          connected: this.socket.connected,
        });
        this.setupSocketListeners();
        this.subscribeToAllUpdates();
      });

      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', {
          error: error.message,
          socketUrl,
          socketId: this.socket?.id,
        });
      });
    } catch (error) {
      console.error('Error in SimpleDatafeed initialization:', error);
      this.socket = io(socketUrl, { autoConnect: false });
    }
  }

  private initializeSocket(): void {
    try {
      const socketUrl = 'http://localhost:3000';
      console.log('Initializing socket with URL:', socketUrl);

      // Create socket with minimal config first
      this.socket = io(socketUrl, {
        path: '/api/socketio',
        transports: ['websocket'],
        autoConnect: true,
      });

      // Set up connection logging
      this.socket.on('connect', () => {
        console.log('Socket connected successfully:', {
          id: this.socket.id,
          url: socketUrl,
          connected: this.socket.connected,
        });
        this.setupSocketListeners();
        this.subscribeToAllUpdates();
      });

      // Handle connection errors
      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', {
          error,
          socketUrl,
          socketId: this.socket.id,
          readyState: this.socket.connected,
        });
      });
    } catch (error) {
      console.error('Error initializing socket:', error);
    }
  }
  private setupSocketListeners(): void {
    if (!this.socket) {
      console.error('Cannot setup listeners - socket not initialized');
      return;
    }

    console.log('Setting up socket listeners');

    this.socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect' || reason === 'transport close') {
        setTimeout(() => this.socket.connect(), 1000);
      }
    });

    this.socket.on('dataUpdate', (data: any) => {
      console.log('Received data update:', data);
      this.handleDataUpdate(data);
    });

    this.socket.io.on('reconnect', (attempt) => {
      console.log('Socket reconnected after attempt:', attempt);
      this.subscribeToAllUpdates(); // Resubscribe after reconnection
    });
  }

  private getSymbolIcon(symbol: string): string {
    if (this.symbolIcons[symbol]) {
      return this.symbolIcons[symbol];
    }

    if (symbol.includes(':')) {
      const [category] = symbol.split(':');
      if (
        [
          'INFLATION',
          'EMPLOYMENT',
          'INTEREST',
          'MACRO',
          'FED',
          'MONEY',
          'INDEX',
        ].includes(category)
      ) {
        return '/dashboard/us-flag.svg';
      }
    }

    const brand = symbol.split(':')[0].toUpperCase();
    return this.symbolIcons[brand] || '/dashboard/default-icon.svg';
  }

  private async fetchSymbolDataRange(
    symbol: string
  ): Promise<{ firstDataPoint: number; lastDataPoint: number }> {
    try {
      const response = await fetch(
        `/api/trading-dashboard/getDataRange?symbol=${encodeURIComponent(symbol)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const range = await response.json();
      this.symbolDataRanges[symbol] = range;
      return range;
    } catch (error) {
      console.error('Error fetching data range:', error);
      throw error;
    }
  }

  onReady(callback: (configuration: object) => void): void {
    setTimeout(
      () =>
        callback({
          supported_resolutions: ['1D', '1W', '1M'] as ResolutionString[],
          supports_marks: false,
          supports_timescale_marks: false,
          supports_time: true,
          exchanges: [
            {
              value: 'WatchInspect',
              name: 'WatchInspect',
              desc: 'Watch Market Data',
            },
            {
              value: 'Economic',
              name: 'Economic',
              desc: 'Economic Indicators',
            },
          ],
          symbols_types: [
            { name: 'watch', value: 'watch' },
            { name: 'economic', value: 'economic' },
          ],
        }),
      0
    );
  }

  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: (result: SearchSymbolResultItem[]) => void
  ): void {
    fetch('/api/trading-dashboard/watchData')
      .then((response) => response.json())
      .then((watchData: WatchData[]) => {
        const searchTerm = userInput.toLowerCase();
        const filteredSymbols = watchData
          .filter(
            (item) =>
              item.symbol.toLowerCase().includes(searchTerm) ||
              item.description.toLowerCase().includes(searchTerm) ||
              (item.subModel &&
                item.subModel.toLowerCase().includes(searchTerm))
          )
          .map(
            (item) =>
              ({
                symbol: item.symbol,
                full_name: item.fullName,
                description: item.description,
                subModel: item.subModel,
                exchange: item.exchange,
                ticker: item.ticker,
                type: item.type,
                dataCount: item.dataCount,
                exchange_logo: this.getSymbolIcon(item.symbol),
              }) as ExtendedSearchSymbolResultItem
          );

        filteredSymbols.sort((a, b) => (b.dataCount || 0) - (a.dataCount || 0));
        onResult(filteredSymbols);
      })
      .catch((error) => {
        console.error('Error searching symbols:', error);
        onResult([]);
      });
  }
  async resolveSymbol(
    symbolName: string,
    onResolve: (symbolInfo: LibrarySymbolInfo) => void,
    onError: (reason: string) => void
  ): Promise<void> {
    try {
      const response = await fetch(
        `/api/trading-dashboard/symbolInfo?symbol=${encodeURIComponent(symbolName)}`
      );
      const data = await response.json();

      if (response.ok) {
        data.exchange_logo = this.getSymbolIcon(symbolName);
        await this.fetchSymbolDataRange(symbolName);
        onResolve(data);
      } else {
        onError(data.error || 'Symbol not found');
      }
    } catch (error) {
      console.error('Error resolving symbol:', error);
      onError('Failed to resolve symbol');
    }
  }

  private getWeekStartTime(timestamp: number): number {
    const date = new Date(timestamp);
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 1);
    return date.getTime();
  }

  private static ECONOMIC_PREFIXES = [
    'INFLATION:',
    'INTEREST:',
    'INDEX:',
    'METAL:',
    'EMPLOYMENT:',
    'MACRO:',
    'FED:',
    'MONEY:',
  ];
  private async loadAllBars(symbol: string): Promise<Bar[]> {
    try {
      if (symbol === 'ROLEX:INDEX') {
        const response = await fetch('/api/charts/index/rolex-index');
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        const bars = data.map((candlestick: any) => ({
          time: candlestick.time,
          open: candlestick.open,
          high: candlestick.high,
          low: candlestick.low,
          close: candlestick.close,
          volume: candlestick.volume || 0,
        }));

        // Sort and cache
        bars.sort((a: Bar, b: Bar) => a.time - b.time);
        this.cachedBars[symbol] = bars;
        return bars;
      }

      const range = await this.fetchSymbolDataRange(symbol);
      const isEconomicData = SimpleDatafeed.ECONOMIC_PREFIXES.some((prefix) =>
        symbol.startsWith(prefix)
      );

      const response = await fetch(
        `/api/trading-dashboard/getBars?symbol=${encodeURIComponent(symbol)}&from=${
          range.firstDataPoint
        }&to=${range.lastDataPoint}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const bars = data.map((bar: any) => ({
        time: Math.floor(bar.time / 1000) * 1000,
        open: Number(bar.open),
        high: Number(bar.high),
        low: Number(bar.low),
        close: Number(bar.close),
        volume: Number(bar.volume || 0),
      }));

      bars.sort((a: Bar, b: Bar) => a.time - b.time);
      const processedBars = isEconomicData ? bars : this.applySmoothing(bars);

      this.cachedBars[symbol] = processedBars;
      return processedBars;
    } catch (error) {
      console.error('Error loading all bars:', error);
      return [];
    }
  }

  private applySmoothing(bars: Bar[]): Bar[] {
    const period = 20;
    return bars.map((bar, index) => {
      if (index < period) return bar;

      const window = bars.slice(Math.max(0, index - period + 1), index + 1);
      const weightedSum = window.reduce((sum, b, i) => {
        const weight = Math.exp(i / window.length);
        return sum + b.close * weight;
      }, 0);
      const weights = window.reduce(
        (sum, _, i) => sum + Math.exp(i / window.length),
        0
      );

      return {
        ...bar,
        close: weightedSum / weights,
      };
    });
  }

  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: {
      from: number;
      to: number;
      countBack: number;
      firstDataRequest: boolean;
    },
    onResult: (bars: Bar[], meta: { noData?: boolean }) => void,
    onError: (reason: string) => void
  ): Promise<void> {
    const { from, to } = periodParams;
    const symbol = symbolInfo.name;

    try {
      if (!this.cachedBars[symbol]) {
        await this.loadAllBars(symbol);
      }

      let bars = this.cachedBars[symbol] || [];

      // Normalize timestamps based on resolution
      if (resolution === '1W') {
        bars = bars.map((bar) => ({
          ...bar,
          time: this.getWeekStartTime(bar.time),
        }));

        // Remove duplicate weekly bars
        const uniqueBars = new Map<number, Bar>();
        bars.forEach((bar) => {
          const weekTime = bar.time;
          if (
            !uniqueBars.has(weekTime) ||
            bar.time > uniqueBars.get(weekTime)!.time
          ) {
            uniqueBars.set(weekTime, bar);
          }
        });
        bars = Array.from(uniqueBars.values());
      }

      // Filter bars for the requested range
      bars = bars.filter(
        (bar) => bar.time >= from * 1000 && bar.time <= to * 1000
      );

      // Sort bars by time
      bars.sort((a, b) => a.time - b.time);

      // Remove any remaining duplicates
      const uniqueBars = Array.from(
        new Map(bars.map((bar) => [bar.time, bar])).values()
      );

      console.log(
        `Returning ${uniqueBars.length} bars for ${symbol} with resolution ${resolution}`
      );
      onResult(uniqueBars, { noData: uniqueBars.length === 0 });
    } catch (error) {
      console.error('Error getting bars:', error);
      onError('Failed to load bars');
    }
  }

  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: (bar: Bar) => void,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): void {
    this.subscribers[symbolInfo.name] = onTick;
    this.socket.emit('subscribeTicker', symbolInfo.name);
  }

  unsubscribeBars(listenerGuid: string): void {
    const symbol = Object.keys(this.subscribers).find(
      (key) => this.subscribers[key] === this.subscribers[listenerGuid]
    );
    if (symbol) {
      delete this.subscribers[symbol];
      this.socket.emit('unsubscribeTicker', symbol);
    }
  }

  private handleDataUpdate(change: any): void {
    if (
      change.operationType === 'insert' ||
      change.operationType === 'update'
    ) {
      const symbol = `${change.fullDocument.brand}:${change.fullDocument.models[0].name}`;
      const latestListing =
        change.fullDocument.models[0].listings[
          change.fullDocument.models[0].listings.length - 1
        ];

      if (latestListing && this.subscribers[symbol]) {
        const bar: Bar = {
          time: latestListing.data[0] * 1000,
          open: latestListing.data[1],
          high: latestListing.data[1],
          low: latestListing.data[1],
          close: latestListing.data[1],
          volume: 0,
        };
        this.subscribers[symbol](bar);
      }
    }
  }

  subscribeToAllUpdates(): void {
    console.log('Subscribing to all updates');
    this.socket.emit('subscribeToAllUpdates');
  }
}
